<script setup>
const props = defineProps({
    text: String,
    required: true
})

const { locale: { value: localCode } } = useI18n();

</script>

<template>
    <div>
        <ContentDoc :query="{ locale: localCode, path: props.text }" />
    </div>
</template>

<style lang="scss" scoped></style>
